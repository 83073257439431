<template>
    <div>
        <h2>Plakát / emléklap</h2>
        <image-galery subfolder="archive2020"></image-galery>
        <h2>Ötpróba</h2>
        <p>
        2019-ben volt először, hogy nem csak meghallgattuk az előadásokat, hanem feladványokat is kaptunk, amikkel közelebb kerültünk
        egymáshoz párunkkal. A résztvevők nagy többsége számára nagy élmény volt ez a pár óra, amit egymásra figyelve töltöttek el.
        </p>
        <p>
        Szeretnénk idén is pár apró feladattal segíteni benneteket a beszélgetésben, bátran vágjatok bele! Összekészítettünk kis 
        egységcsomagokat, ezeket Verőce-Kismaros-Nagymaros templomaiban, a sekrestyében tudjátok átvenni, illetve a helyi szervezőktől bármikor kérhettek!!!
        </p>  
        <p>
        Ha segítségre van szükségetek, hívjátok a <router-link to="/contacts">helyi szervezőket!</router-link>
        Kérünk a csomag átvételekor adjátok meg elérhetőségeiteket, sok praktikus aktuális információval tudunk majd segíteni benneteket.
        </p>
        <div class="list-wrapper">
        <h3>Idén az alábbi feladatok várnak rátok, csak dióhéjban:</h3>
        <ol>
            <li>Kirándulás KETTESBEN (nem a gyerekekkel-barátokkal)</li>
            <li>Részt vesztek legalább 1 előadáson</li>
            <li>Sétáljatok és beszélgessetek a megadott kérdések vezetésével.</li>
            <li>Készítsetek egy fényképet magatokról, ahol egy szívecskét formáztok meg a képen. Mindegy hogyan, füsttel, levelekkel, rajzzal…. 
                A kép annyi személyes infót mindenképp tartalmazzon, hogy kiderüljön ki küldte a képet! Kérünk, február 12-ig küldjétek el a településetek kontakt email címére!</li>
            <li>Töltsetek otthon egy estét kettesben! Kaptok pár kérdést és 2 finom jó éjt teafiltert.</li>
        </ol>
        </div>
        <p>Az egységcsomagokban minden további információt megtaláltok.</p>
        <p>
        Minden feladat teljesítése után köthettek egy csomót a csomagban található kötelekből. A végére lesz egy karkötőtök, amit örök emlékül eltehettek magatoknak. 
        </p>

        <h2>Paracord</h2>
        <p>A csomó kötésben az alábbi képek biztosan segíteni fognak. Profiknak javasoljuk a <a href="http://paracord.hu" target="blank">paracord.hu</a> weboldal böngészését.</p>
        <image-galery subfolder="trials2020"></image-galery>
        
        <h2>Kirándulás</h2>
        <p>A szöveges útleírást megtaláljátok az egységcsomagban. Az alábbi térképeken részletes navigációt találhattok.</p>
        <embedded-map src="https://www.termeszetjaro.hu/hu/embed/126771343/js?mw=false" title="Kismaros-Verőce legrövidebb táv"></embedded-map>
        <embedded-map src="https://www.termeszetjaro.hu/hu/embed/126771631/js?mw=false" title="Kismaros-Verőce közepes táv"></embedded-map>
        <embedded-map src="https://www.termeszetjaro.hu/hu/embed/126771632/js?mw=false" title="Kismaros-Verőce leghosszabb táv"></embedded-map>
        <embedded-map src="https://www.termeszetjaro.hu/hu/embed/126982348/js?mw=false" title="Nagymaros"></embedded-map>
    </div>
</template>

<script>
  import EmbeddedMapComponent from './EmbeddedMapComponent.vue'
  import ImageGaleryComponent from './ImageGaleryComponent.vue'

  export default {
    name: 'archive-2020',
    components: {
        'embedded-map': EmbeddedMapComponent,
        'image-galery': ImageGaleryComponent
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h2:first-child {
        margin-top: 20px;
    }
</style>
